import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Card from '../components/Card'
import Helmet from 'react-helmet'
import Container from '../components/Container'
// import CardList from '../components/CardList'
// import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import styled from 'styled-components'

import mainTeaserImage from '../../static/mainteaserimage.png'
import playstore from '../../static/playstore.jpg'
import appstore from '../../static/appstore.jpg'

import aboutTeaser01 from '../../static/aboutTeaser01.png'
import aboutTeaser02 from '../../static/aboutTeaser02.png'
import aboutTeaser05 from '../../static/aboutTeaser05.png'
import aboutTeaser04 from '../../static/aboutTeaser04.png'

const Header = styled.div`
  padding-bottom: 0;
  height: 80vh;

  > section {
    padding-top: 0;
    padding-bottom: 0;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {  
      display: flex;
      align-items: center;
    }
  }

  .mainTeaserImageContainer {
    flex: 1 0 30%;
    margin-top: auto;

    .mainTeaserImage {
      width: 100%;
    }
  }

  .mainTeaserText {
    width: 100%;
    height: 95vh;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 32px;
    line-height: 1.25;
    font-weight: bold;
    text-align: center;

    @media screen and (min-width: ${props => props.theme.responsive.medium}) { 
      font-size: 56px;
      line-height: 1.25;
    }

    span {
      color: ${props => props.theme.colors.highlight};
    }
  }

  p {
    color: #aaa;
    font-size: 14px;
    line-height: 1.75;
    text-align: center;

    @media screen and (min-width: ${props => props.theme.responsive.small}) { 
      text-align: left;
    }

    + p {
      margin-top: 2rem;
    }
  }

  p.semibig {
    font-size: 18px;
  }

  .storeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        justify-content: flex-start;
      }
    a {
      padding: .25rem .5rem;
      background-color: #000;
      border-radius: .5rem;
      box-shadow: 0 4px 8px rgba(0,0,0,.5);
    }
    > a:first-child {
      margin-right: .5rem;
    }
    > a:hover {
      opacity: .75;
    }
  }

  .storeBadge {
    width: auto;
    height: 40px;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        height: 48px;
      }
  }

  .linkContainer {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }

  .mainTeaserButton {
    font-size: 14px;
    padding: .5rem .75rem;
    border: 1px solid rgba(255,255,255,.25);
    border-radius: .25rem;
    text-decoration: none;
    color: #fff;

    & + .mainTeaserButton {
      margin-left: .5rem;
    }
  }
`

const AboutSection = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.colors.base};
  border-bottom: 2px solid ${props => props.theme.colors.baseDark};

  > section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {  
      flex-direction: row;
    }
  }
`

const AboutText = styled.div`
  flex: 1;

  h2 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 1rem;
    text-align: center;

    @media screen and (min-width: ${props => props.theme.responsive.small}) { 
      text-align: left;
      margin-bottom: 2rem;
    }
  }

  p {
    color: #aaa;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 1px;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {  
      text-align: left;
    }
  }
`

const AboutImage = styled.div`
  flex: 1;
  order: 99;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {  
    display: block;
    order: initial;
  }
`

const Index = ({ data, pageContext }) => {
  const posts = data.allContentfulPost.edges
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1

  return (
    <Layout>
      <SEO />
      {!isFirstPage && (
        <Helmet>
          <title>{`${config.siteTitle} - Page ${currentPage}`}</title>
        </Helmet>
      )}
      <Header>
        <Container>
          <div className="mainTeaserText">
            <h1>A <span>new</span> snoopo is coming soon!</h1>
          </div>
        </Container>
      </Header>

      {/*
        <Container>
          <CardList>
            {posts.slice(1).map(({ node: post }) => (
                <Card key={post.id} {...post} />
            ))}
          </CardList>
          <Pagination context={pageContext} />
        </Container>
      */}

      <AboutSection>
        <Container>
          <AboutText>
            <h2>Our Vision</h2>
            <p>snoopo is a new thrilling platform that enables people all around the world to find treasures in the form of cash, vouchers and snoopo coins.</p>
          </AboutText>
          <AboutImage>
            <img src={aboutTeaser01} className="mainTeaserImage" />
          </AboutImage>
        </Container>
      </AboutSection>

      <AboutSection>
        <Container>
          <AboutImage>
            <img src={aboutTeaser04} className="mainTeaserImage" />
          </AboutImage>
          <AboutText>
            <h2>Your Adventure</h2>
            <p>Go out and start your treasure hunt. The app helps you to find the boxes in your city. Whenever you see the blue dotted scale filling up, you are getting closer and closer.</p>
          </AboutText>
        </Container>
      </AboutSection>

      <AboutSection>
        <Container>
          <AboutText>
            <h2>Our Mission</h2>
            <p>snoopo creates a virtual experience in the real world. With the help of Augmented Reality, you can discover snoopos whenever you are close enough to them.</p>
          </AboutText>
          <AboutImage>
            <img src={aboutTeaser02} className="mainTeaserImage" />
          </AboutImage>
        </Container>
      </AboutSection>

      <AboutSection>
        <Container>
          <AboutImage>
            <img src={aboutTeaser05} className="mainTeaserImage" />
          </AboutImage>
          <AboutText>
            <h2>Your Game</h2>
            <p>Connect with other players and be part of the snoopo community.</p>
          </AboutText>
        </Container>
      </AboutSection>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`

export default Index
